import React from "react";
import blockStyles from "./blocks.module.css";

export default (props) => {
  const { heading, description, borderCol, outlineCol } = props;
  return (
    <div
      className={blockStyles.block}
      style={{
        borderBottomColor: borderCol,
        borderRightColor: borderCol,
        outlineColor: outlineCol,
      }}
    >
      <h6>{heading}</h6>
      <p>{description}</p>
    </div>
  );
};
