import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { rgb } from "d3";
import { scaleLinear } from "d3-scale";
import Global from "./../components/Global";
import SEO from "./../components/seo";
import Moniker from "./../components/Moniker";
import ScrollSection from "./../components/ScrollSection";
import BlockContainer from "./../components/Blocks/BlockContainer";
import Footer from "./../components/Footer";

export default (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     height: window.innerHeight,
  //   };
  // }

  //   componentWillMount() {
  //     window.addEventListener("resize", this.handleWindowSizeChange);
  //     window.addEventListener("scroll", this.listenToScroll);
  //   }

  //   componentWillUnmount() {
  //     window.removeEventListener("resize", this.handleWindowSizeChange);
  //     window.removeEventListener("scroll", this.listenToScroll);
  //   }

  //   handleWindowSizeChange = () => {
  //     this.setState({ height: window.innerHeight });
  //   };

  //   listenToScroll = () => {
  //     const winScroll =
  //       document.body.scrollTop || document.documentElement.scrollTop;

  //     const height =
  //       document.documentElement.scrollHeight -
  //       document.documentElement.clientHeight;

  //     const scrolled = winScroll / height;

  //     this.setState({
  //       theposition: scrolled,
  //     });
  //   };

  //const { height, theposition } = this.state;
  const [colVal, setColVal] = useState(true);
  const [compColVal, setCompColVal] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;
    const height = document.documentElement.scrollHeight; //window.outerHeight;

    const c = scaleLinear()
      .domain([0, height])
      .range([`rgb(242, 166, 50)`, `rgb(230, 32, 45)`]);

    //const cC = scaleLinear().domain([0, height]).range([`#3d76c4`, `#9ff5f2`]);
    const cC = scaleLinear().domain([0, height]).range([`#26acd9`, `#99fffc`]);

    setColVal(c(position));
    setCompColVal(rgb(cC(position)).brighter());
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <SEO />
      <Global bgColor={colVal} compColor={compColVal} />
      <div className="container">
        <Moniker compColor={compColVal} />
        <ScrollSection
          heading="What do we want to happen to us?"
          sectionCount="01"
        >
          <p>
            Design has come to be seen as inextricably tied to the decisions we
            make about the lives we live and the worlds in which we live them.
            Increasingly, the responsibility is becoming clear and pressing for
            designers to bring ethical objects, ideas and experiences into
            being, and for design practice and discourse to be concerned with
            how things <em>ought to be</em> in order to attain meaningful,
            desirable and enduring outcomes.
          </p>

          <p>
            My name is <Link to="/about">Wynand Kok</Link>, and I’m a Design
            Researcher interested in projects that work towards such outcomes.
          </p>

          <p>
            Over the course of my career, I have had the opportunity to work
            with visionary UX design teams and immensely talented technologists
            in delivering value to customers through accessible, design-led and
            data-informed solutions, getting my hands dirty with a diverse array
            of exciting digital technologies and user research techniques along
            the way. However, I've kept coming back to Humberto Maturana's
            incisive observation:
          </p>

          <blockquote>
            <h5 style={{ color: compColVal }}>
              The question we humans must face is that of what do we want to
              happen to us, not a question of knowledge or progress.
            </h5>
          </blockquote>

          <p>
            Most recently, a lot of my interest has been in building
            high-impact, sustainable design teams within organisations, and the
            role of design in facilitating structures to support meaningful
            impact.
          </p>
        </ScrollSection>
        <ScrollSection heading="Lean Design Research" sectionCount="02">
          <p>
            What would a Design Research process look like that incorporates
            continual improvement as a space to explore new ways of approaching
            familiar steps in the research process?; can Design Research place
            the experience of human "research subjects" at the center of design
            of how this research is conducted? In exploring these questions, the
            Lean Research framework stands out as a guide to how Design Research
            in practice can create new value.
          </p>
          <p>
            Emerging from the work of scholars, practitioners and donors in the
            contexts of international development and humanitarian work, Lean
            Research offers a guiding framework for conducting research and
            evaluation. It is an approach to doing research, meaning it involves
            a set of practices - of things that we do differently compared to
            conventional research - which makes it suitable to the realm of
            Design Research. By being founded upon four interrelated principles
            of good research practice, it also gives us a vital point of
            departure for conducting Design Research.
          </p>
          <BlockContainer
            borderCol={colVal}
            outlineCol={compColVal}
            blockContent={[
              {
                heading: `Rigor`,
                description: `High standards of rigor ensure the integrity of the research process and results, a pre-condition for research that is respectful of participants’ time and is usable by research stakeholders.`,
              },
              {
                heading: `Relevance`,
                description: `Relevant research has clear value to stakeholders and addresses priority issues and questions for
                research subjects, decision-makers, and also the design community.`,
              },
              {
                heading: `Right size`,
                description: `Right-sized research is only as time-
                consuming, burdensome, and costly as it needs to be, and all unnecessary questions, activities, and protocols are removed.`,
              },
              {
                heading: `Respect`,
                description: `Respectful research places the dignity and delight of the human subject at the center of the research experience.`,
              },
            ]}
          />
          <p>
            Lean Research does not provide a set of rules to follow, but rather
            a guiding orientation to encourage innovation and continual
            improvement in research practice. Our goal as designers is to understand how
            users interpret their own involvement with the world as meaningful
            activity, and a Lean Reasearch orientation helps us understand the actions users take and how those
            actions result in meaning formation.
          </p>
        </ScrollSection>
        {/*<ScrollSection heading="Rigor" sectionCount="03">
          <p>Coming...</p>
        </ScrollSection>
        <ScrollSection heading="Relevance" sectionCount="04">
          <p>Coming...</p>
        </ScrollSection>
        <ScrollSection heading="Right Size" sectionCount="05">
          <p>Coming...</p>
        </ScrollSection>
        <ScrollSection heading="Respect" sectionCount="06">
          <p>Coming...</p>
        </ScrollSection>
        <ScrollSection heading="Messy Evidence and Design Hermeneutics" sectionCount="07">
          <blockquote>
            <h5 style={{ color: compColVal }}>
              We use design to interpret the world.
            </h5>
          </blockquote>
          <p>
            Whereas the natural sciences are focused on data with properties,
            the human sciences seek out data that sheds light on the way people
            experience those properties; shed light on the phenomena. The data
            driving the study of phenomena is not models or equations, but is
            made up of pictures, emotions, artifacts, observed behavior, and
            conversations. The analytical frameowk helps make invisible patterns
            visible. Problems with hight levels of uncertainty; Qualitative
            evidence. Messy evidence Coming... In using Lean Research as a
            framework to guide Design Research, it means we are looking for
            opportunities to align what we do not just with one principle or
            another, but with all four - even if we choose to start by working
            on a specific one that feels most important in the context of what
            we're trying to do a the time.
          </p>
          <p>
            Observing coping strategies and building that insight back into the
            design process
          </p>
          <blockquote>
            <h5 style={{ color: compColVal }}>
              Breakdowns are a necessary accompaniment to design, not because
              the designer lacks competence, but because of the nature of the
              design process. A breakdown is not a negative situation which one
              has to avoid. It is the situation of what is not self-evident. A
              breakdown reveals the tissue of the relations needed to fulfill
              the tasks. From this follows a clear target for design:
              Anticipating the forms of breakdowns and opening up possibilities
              for the action in case they happen.
            </h5>
            <span className="author">- Gui Bonsiepe</span>
          </blockquote>
          <p>Ambiguity and shit</p>
        </ScrollSection>
        <ScrollSection heading="Ontological Design" sectionCount="08">
          <blockquote>
            <h5 style={{ color: compColVal }}>
              Every Dasein is styled through the consumption of design. Without
              design, Dasein is meaningless
            </h5>
          </blockquote>
          <p>
            <Link to="/about">Coming...</Link> Post-phenomenological Design
            Thinking The world exposes or reveals itself when we manipulate it;
            through interactions with things, we come to know about ourselves
            and our surroundings. Our goal as designers is to understand how
            users interpret their own involvement with the world as meaningful
            activity, We are interested in what actions users take and how those
            actions result in meaning formation.
          </p>
        </ScrollSection>
         */}
        <h3 className="section-count">/02</h3>
        <Footer />
      </div>
    </>
  );
};
