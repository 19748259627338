import React from "react";
import Block from "./Block";
import blockStyles from "./blocks.module.css";

export default (props) => {
  const { blockContent, borderCol, outlineCol } = props;
  return (
    <div className={blockStyles.blockContainer}>
      {blockContent.map((block, index) => (
        <Block
          key={`block_` + index}
          heading={block.heading}
          description={block.description}
          borderCol={borderCol}
          outlineCol={outlineCol}
        />
      ))}
    </div>
  );
};
